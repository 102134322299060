<template>
    <div class="loading-cnt" v-if="loading">
        <van-loading size="16px">加载中...</van-loading>
    </div>
</template>

<script>
export default {
    name: "MiddleLoading",
    props: {
        loading: {
            required: true,
            type: Boolean,
            default () {
                return false
            }
        }
    }
}
</script>

<style scoped>
    .loading-cnt {
        margin-top: 8px;
        text-align: center;
    }
</style>
