<template>
    <middle-loading :loading="volumeLoading" v-if="volumeLoading" />

    <div v-else>
        <van-tabs v-model="active" animated color="#1989fa" class="volume-tabs">
            <van-tab :key="key" v-for="(items, key) in bibles" :title="key">
                <van-grid square>
                    <van-grid-item
                        :class="item.id === volume.id ? 'grid-item-active' : null"
                        v-for="item in items"
                        :key="item.id"
                        @click="handleVolumeClick(item)"
                    >
                        <template #icon>
                            <p class="short-name">{{item.short_name}}</p>
                        </template>
                        <template #text>
                            <p class="full-name">{{item.full_name}}</p>
                        </template>
                    </van-grid-item>
                </van-grid>
            </van-tab>
        </van-tabs>
        <van-action-sheet :round="false" v-model="show" :title="volume.full_name || ''" class="bible-popup volume-action-sheet">
            <middle-loading :loading="chapterLoading" v-if="chapterLoading" />

            <van-tabs v-model="panelActive" animated sticky color="#1989fa" v-else>
                <van-tab name="chapterTab" :title="humanChapterName">
                    <van-grid square :column-num="6" :border="false">
                        <van-grid-item
                            :class="cId === chapterSn ? 'grid-item-active' : null"
                            v-for="(verses, cId) in chapters"
                            :key="cId"
                            @click="handleChapterClick(cId, verses)"
                        >
                            <template #icon>
                                <p class="short-name">{{cId}}</p>
                            </template>
                        </van-grid-item>
                    </van-grid>
                </van-tab>
                <van-tab name="verseTab" :title="humanVerseName" :disabled="verses.length === 0">
                    <van-grid square :column-num="6" :border="false">
                        <van-grid-item
                            :class="verse.verse_sn === verseSn ? 'grid-item-active' : null"
                            v-for="verse in verses"
                            :key="verse.verse_sn"
                            @click="handleVerseClick(verse.verse_sn)"
                        >
                            <template #icon>
                                <p class="short-name">{{verse.verse_sn}}</p>
                            </template>
                        </van-grid-item>
                    </van-grid>
                </van-tab>
            </van-tabs>
        </van-action-sheet>
        <chapter-reader :volume="volume" :dataset="fullVerses" :start="startVerse" ref="reader" />
    </div>
</template>

<script>
import { getBibles, getChapters, getVerses } from '@/api/bible'
import ChapterReader from '@/components/ChapterReader'
import { VOLUME_CHAPTER_VERSES, VOLUME_CHAPTERS } from '@/utils/constants'
import MiddleLoading from '@/components/MiddleLoading'

export default {
    name: 'Bible',
    components: { ChapterReader, MiddleLoading },
    data () {
        return {
            panelActive: 'chapterTab',
            volumeLoading: true,
            chapterLoading: false,
            verseLoading: false,
            active: 0,
            bibles: {},
            chapters: [],
            show: false,
            volume: {
                id: 0
            },
            chapterSn: 0,
            verseSn: 0,
            verses: [],
            fullVerses: []
        }
    },
    computed: {
        startVerse () {
            return {
                volume_sn: this.volume.id,
                chapter_sn: this.chapterSn,
                verse_sn: this.verseSn
            }
        },
        humanChapterName () {
            if (this.chapterSn === 0) {
                return '章'
            }
            return `章 (第${this.chapterSn}章)`
        },
        humanVerseName () {
            if (this.verseSn === 0) {
                return '节'
            }
            return `节 (第${this.verseSn}节)`
        }
    },
    methods: {
        handleVolumeClick (item) {
            this.show = true
            if (this.volume.id === item.id) {
                return
            }
            this.volume = item
            this.verses = []
            this.chapterSn = 0
            this.verseSn = 0
            this.panelActive = 'chapterTab'
            this.chapterLoading = true
            const cacheKey = VOLUME_CHAPTERS(item.id)
            const cachedChapters = this.$ls.get(cacheKey)
            if (cachedChapters) {
                this.chapters = cachedChapters
                this.chapterLoading = false
            } else {
                getChapters(item.id).then(res => {
                    this.chapters = res.data
                    this.$ls.set(cacheKey, res.data)
                }).finally(() => {
                    this.chapterLoading = false
                })
            }
        },
        handleChapterClick (chapterSn, verses) {
            this.verseLoading = true
            const cacheKey = VOLUME_CHAPTER_VERSES(this.volume.id, chapterSn)
            const cachedVerse = this.$ls.get(cacheKey)
            if (cachedVerse) {
                this.fullVerses = cachedVerse
                this.verseLoading = false
            } else {
                getVerses(this.volume.id, chapterSn).then(res => {
                    this.fullVerses = res.data
                    this.$ls.set(cacheKey, res.data)
                }).finally(() => {
                    this.verseLoading = false
                })
            }

            this.chapterSn = chapterSn
            this.verseSn = 0
            this.verses = verses
            this.$nextTick(() => {
                this.panelActive = 'verseTab'
            })
        },
        handleVerseClick (verseSn) {
            this.verseSn = verseSn
            this.$refs.reader.show();
        }
    },
    created () {
        getBibles().then(res => {
            this.bibles = res.data
        }).finally(() => {
            this.volumeLoading = false
        })
    }
};
</script>

<style lang="less">
    .short-name {
        font-size: 18px;
        margin: 0px;
    }
    .full-name {
        font-size: 14px;
        margin: 0px;
    }
    .grid-item-active {
        color: #1989fa;
    }
    .volume-action-sheet {
        min-height: 80%;
    }
    .volume-tabs {
        .van-tab {
            font-size: 16px;
        }
    }
</style>
