import { axios } from '@/utils/request'

export function getBibles(parameter) {
    return axios({
        url: 'bibles/volumes',
        method: 'get',
        params: parameter
    })
}

export function getChapters(volumeSn) {
    return axios({
        url: `bibles/${volumeSn}/chapters`,
        method: 'get'
    })
}

export function getVerses(volumeSn, chapterSn) {
    return axios({
        url: `bibles/${volumeSn}/chapters/${chapterSn}/verses`,
        method: 'get'
    })
}

export function getPoems(parameter) {
    return axios({
        url: 'poems',
        method: 'get',
        params: parameter
    })
}
