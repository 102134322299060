<template>
    <bible />
</template>

<script>
import Bible from '@/components/Bible'
import wechatMixin from '@/utils/mixin'

export default {
    name: 'BibleIndex',
    mixins: [wechatMixin],
    components: { Bible },
    created() {
        this.registerWechatShare({
            title: '在线圣经',
            description: '【诗119:105】你的话是我脚前的灯，是我路上的光。',
            thumb: 'https://file4.mogody.com/avatars/bible.jpg'
        })
    }
};
</script>
