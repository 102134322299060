<template>
    <div>
        <van-popup
            v-model="showPopup"
            position="bottom"
            class="reader-popup"
            safe-area-inset-bottom
            close-on-popstate
            :lock-scroll="false"
        >
            <van-sticky class="volume-title">
                <p>{{ volume.full_name }} (第 {{start.chapter_sn}} 章)</p>
                <a href="" @click.prevent="showPopup = false">返回目录</a>
            </van-sticky>
            <middle-loading :loading="dataset.length === 0" v-if="dataset.length === 0" />

            <div
                v-for="data in dataset"
                :key="data.verse_sn"
                class="verse-item"
                :id="'verse-' + data.verse_sn"
                v-else
            >
                <div class="verse-sn">
                    <span class="verse-focus" v-if="data.verse_sn === start.verse_sn"></span>
                    {{ data.verse_sn }}.
                </div>
                <p class="verse-content">{{ data.content }}</p>
            </div>
        </van-popup>
    </div>
</template>

<script>
import MiddleLoading from '@/components/MiddleLoading'

export default {
    name: "ChapterReader",
    components: { MiddleLoading },
    props: {
        volume: Object,
        dataset: Array,
        start: {
            type: Object,
            required: true
        }
    },
    methods: {
        show () {
            this.showPopup = true
            this.$nextTick(() => {
                if (this.start.verse_sn <= 4) {
                    return
                }
                const needScroll = this.start.verse_sn - 1
                const scrollTo = needScroll > 2 ? needScroll - 2 : needScroll
                const rendered = window.document.getElementById('verse-' + scrollTo)
                rendered && rendered.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
            })
        }
    },
    data() {
        return {
            showPopup: false
        }
    }
}
</script>

<style lang="less">
    .reader-popup {
        width: 100%;
        height: 100%;
        padding: 10px 16px;
        box-sizing: border-box;

        .volume-title {
            p {
                margin: 0px;
                padding: 10px 0px;
                background: #fff;
                font-size: 18px;
            }
            .van-sticky {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #fff;

                a {
                    color: #1989fa;
                    font-size: 14px;
                }
            }
            .van-sticky--fixed {
                margin: 0px 16px;
            }
        }
        .verse-item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 8px 0px;
            color: #323233;

            .verse-sn {
                margin: 0;
                font-size: 14px;
                line-height: 26px;
                margin-right: 5px;
                position: relative;

                .verse-focus {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    border: 1px solid #f5222d;
                    position: absolute;
                    left: 10px;
                    top: 10px;
                }
            }
            .verse-content {
                margin: 0px;
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
</style>
